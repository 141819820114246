import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const Toggle = styled(motion.div)`
  height: 24px;
  width: 24px;
  border-radius: 5px;
  position: relative;
  top: 4px;
  background: linear-gradient(180deg, #f1f1f1 0%, #ffffff 100%);
`

const Switch = ({ onFlip, initial = true, className }) => {
  const [isOn, switchState] = useState(initial)

  const flip = () => {
    switchState(!isOn)
    onFlip && onFlip()
  }

  const transition = {
    type: "tween",
    duration: 0.2,
  }

  useEffect(() => {
    switchState(initial)
  }, [initial])

  return (
    <motion.div
      style={{
        height: 32,
        width: 56,
        borderRadius: 5,
        position: "relative",
        cursor: "pointer",
      }}
      onTap={flip}
      variants={{
        off: { background: "#bbbbbb" },
        on: { background: "#05C56B" },
      }}
      initial={isOn ? "on" : "off"}
      animate={isOn ? "on" : "off"}
      transition={transition}
      {...className}
    >
      <Toggle
        height={24}
        width={24}
        variants={{
          off: { x: 4, boxShadow: "-2px 0 4px 0 #aaaaaa" },
          on: { x: 28, boxShadow: "-2px 0 4px 0 #00933e" },
        }}
        initial={isOn ? "on" : "off"}
        animate={isOn ? "on" : "off"}
        transition={transition}
      />
    </motion.div>
  )
}

export default Switch
