import React, { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"

import Button from "components/button"
import PlayIcon from "images/icons/play.svg"
import Modal from "components/modal"
import YouTube from "components/youtube.js"
import ParrotImg from "components/img"
import Logo from "images/logos/demand360-lite-product.png"
import LogoRegular from "images/logos/demand360-lite-product-regular.png"
import PlanPrice from "components/plan-price"
import { brandBlock } from "utils/brand"

const MonitorCTAStyles = styled.div`
  ${tw`font-body text-white overflow-hidden`}
  z-index: 1;

  & > .main {
    ${tw`py-32`}

    font-size: 14px;
    line-height: 23px;

    @media (min-width: 640px) {
      font-size: 22px;
      line-height: 33px;
    }
  }

  .cw {
    ${tw`mx-auto max-w-s m:max-w-m l:max-w-l font-bold`}
  }

  .header-wrapper {
    ${tw`mx-auto l:max-w-l font-bold relative`}

    picture.hero img,
    img.hero {
      ${tw`absolute`}

      width: 100%;
      top: 510px;

      @media (min-width: 400px) {
        top: 500px;
      }

      @media (min-width: 640px) {
        top: 0;
        width: auto;
        height: 500px;
        left: 50%;
      }

      @media (min-width: 750px) {
        height: 655px;
      }

      @media (min-width: 850px) {
        height: 755px;
      }

      @media (min-width: 1200px) {
        height: 970px;
      }
    }

    .header {
      ${tw`m:pl-8 l:pl-0`}

      font-size: 15px;
      line-height: 1.6;
      text-align: center;

      height: 750px;

      @media (min-width: 400px) {
        height: 763px;
      }

      @media (min-width: 640px) {
        height: 565px;
        text-align: left;
      }

      @media (min-width: 1200px) {
        height: 612px;
        font-size: 18px;
      }

      .logo {
        width: 351px;

        @media (min-width: 640px) {
          width: 335px;
          margin-top: 30px;
        }

        @media (min-width: 1200px) {
          width: 488px;
        }
      }
    }
  }

  .transparent img {
    margin-top: -3px;
    margin-left: 5px;
  }

  h2 {
    ${tw`font-extrabold`}
    font-size: 38px;
    line-height: 38px;
    padding-top: 30px;
    padding-bottom: 18px;
    text-align: center;
    @media (min-width: 640px) {
      line-height: 1.13;
      text-align: left;
    }
    @media (min-width: 1200px) {
      font-size: 65px;
      line-height: 1.13;
      text-align: left;
    }
  }

  .plan-price {
    ${tw`h-24 l:h-32`}

    background: linear-gradient(180deg, #3e3d3c 0%, #191919 100%);
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1477);
    border-radius: 5px;

    .price {
      ${tw`text-springgreen-light font-body w-full m:w-7/12 font-bold`}
      max-width: auto;
      @media (min-width: 1200px) {
        max-width: 200px;
      }
    }
  }

  .cta {
    .button {
      height: 14px;
    }
  }
`

const LogosSlider = styled.div`
  ${tw`relative w-full`}
  height: 87.5px;
  margin: auto;
  overflow: hidden;

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-167px * ${(props) => props.quantity}));
    }
  }

  .logos-track {
    animation: scroll ${(props) => props.quantity * 3}s linear infinite;
    display: flex;
    width: calc(167px * ${(props) => props.quantity});
  }

  .logo {
    ${tw`flex flex-col items-center justify-center`}
    flex-grow: 0;
    flex-shrink: 0;
    height: 87.5px;
    padding-left: 8.5px;
    padding-right: 8.5px;
    width: 167px;

    img {
      display: block;
      height: 87.5px;
      width: 150px;
      padding: 0;
    }
  }
`

const MonitorCTA = ({ content, logos, playVideo, onPlayVideo }) => {
  const [logosData, setLogosData] = useState([])

  const data = useStaticQuery(graphql`
    query {
      gcms {
        content: contentBlocks(
          where: { identifier: "demand360-lite-subscription-cta" }
          first: 1
          orderBy: order_ASC
        ) {
          title
          headerContent {
            html
          }
          mainContent {
            html
          }
          callToAction
          linkUrl
        }
      }
    }
  `)

  const contentCTA = brandBlock(data.gcms.content[0])

  useEffect(() => {
    if (logos.logos) {
      const newLogos = logos.logos.map((l, idx) => {
        return { order: idx, gen: 0, ...l }
      })
      setLogosData(newLogos)
    }
  }, [logos])

  return (
    <MonitorCTAStyles>
      <div className="main">
        <div className="header-wrapper flex flex-col m:flex-row">
          <div className="header">
            <img className="logo mx-auto m:hidden l:hidden" src={Logo} />
            <img
              className="logo hidden m:inline-block l:inline-block"
              src={LogoRegular}
            />
            <div
              className="pt-8 pb-6 font-normal px-3 l:px-0 m:px-0 m:w-2/5"
              dangerouslySetInnerHTML={{ __html: content.headerContent?.html }}
            />
            <div className="plan-price flex flex-grow w-4/5 mx-auto m:mx-0 l:mx-0 m:w-2/5 l:mt-4">
              <PlanPrice divided={true} />
            </div>

            <div className="cta flex flex-col l:flex-row w-4/5 m:w-2/5 mx-auto m:mx-0 mt-8 l:mt-12 l:space-x-4 space-y-4 l:space-y-0">
              {contentCTA && (
                <Button to={contentCTA.linkUrl} className="w-full l:w-1/2">
                  <div className="button">{contentCTA.callToAction}</div>
                </Button>
              )}

              {content.extraData?.VideoCTA && (
                <Button
                  onClick={() => onPlayVideo(true)}
                  className="w-full l:w-1/2 secondary white-text"
                >
                  <div className="flex flex-row items-center justify-center button">
                    <img
                      src={PlayIcon}
                      className="mr-3 mb-1"
                      height="20"
                      width="20"
                    />
                    {content.extraData.VideoCTA}
                  </div>
                </Button>
              )}
            </div>
          </div>
          {content.featuredImage && (
            <ParrotImg className="hero" src={content.featuredImage} />
          )}
        </div>
        {logosData.length > 0 && (
          <div className="relative z-10">
            <div className="cw text-center font-display pb-10">
              {logos.heading}
            </div>
            <LogosSlider quantity={logosData.length}>
              <div className="logos-track">
                {[0, 1].map((i) => {
                  return logosData.map((l) => (
                    <div
                      className="logo"
                      key={`${i}-${l.order}`}
                      id={`${i}-${l.order}`}
                    >
                      <img src={l.url} alt={l.altText} />
                    </div>
                  ))
                })}
              </div>
            </LogosSlider>
          </div>
        )}
      </div>
      {content.extraData?.YouTubeID && (
        <Modal isVisible={playVideo} onClose={() => onPlayVideo(false)}>
          <div className="video-player h-full w-full m:px-16">
            <YouTube
              videoId={content.extraData.YouTubeID}
              active={true}
              autoplay={true}
              fixAspectRatio={false}
            />
          </div>
        </Modal>
      )}
    </MonitorCTAStyles>
  )
}

export default MonitorCTA
