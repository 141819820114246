import React, { useContext, useEffect, useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import CouponContext from "context/coupon-context"

const Style = styled.div`
  ${tw`flex flex-col items-center pt-16 m:pb-8 m:pt-32 m:-mb-32 font-body text-white text-center max-w-s mx-auto m:max-w-m l:max-w-l`}
  position: relative;
  z-index: 2;

  h2 {
    ${tw`font-display font-bold text-springgreen-light border-springgreen-light mb-6`}
    font-size: 30px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    @media (min-width: 720px) {
      font-size: 40px;
    }
  }

  .copy {
    ${tw`l:w-2/3`}
    font-size: 16px;
    max-width: 90%;
    text-align: center;
    @media (min-width: 720px) {
      font-size: 20px;
    }
  }

  a {
    ${tw`text-springgreen-light`}

    &:hover {
      text-decoration: undeline;
    }
  }

  .partner-logo {
    ${tw`flex flex-col items-center justify-center mb-8`}
    border-radius: 5px;
    box-shadow: 4px 4px 15px 0 rgba(129, 129, 129, 0.15);
    background-color: #ffffff;
    height: 126px;
    padding: 20px;
    width: 334px;
    max-width: 90%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`

const CouponBanner = () => {
  const [couponValid, setCouponValid] = useState(false)
  const [partner, setPartner] = useState(null)
  const [loading, setLoading] = useState(true)
  const context = useContext(CouponContext)
  const {
    gcms: { partners },
  } = useStaticQuery(graphql`
    {
      gcms {
        partners {
          id
          name
          logo {
            url
          }
        }
      }
    }
  `)

  useEffect(() => {
    let ignore = false
    async function fetchData() {
      const coupon = await context.getCoupon()
      if (coupon) {
        setCouponValid(coupon?.valid === true)
      }
      const partnerId = coupon?.metadata?.partner_id || context.partnerId
      if (partnerId) {
        const partner = partners.find((p) => p.id == partnerId)
        setPartner(partner)
      }
      setLoading(false)
    }
    fetchData()
    return () => {
      ignore = true
    }
  }, [context])

  if (context.coupon || context.partnerId) {
    if (loading) {
      return (
        <Style>
          <div className="copy">Verifying coupon...</div>
        </Style>
      )
    } else {
      if (partner) {
        return (
          <Style>
            {partner.logo?.url && (
              <div className="partner-logo mt-8">
                <img src={partner.logo.url} alt="" />
              </div>
            )}
            <h2 className="">
              <span className="text-white">{partner.name} special offer:</span>{" "}
              Coupon recognized
            </h2>
            <div className="copy">
              Please <Link to="/start/">proceed to checkout</Link> to redeem
              your{" "}
              <span className="demand360-brand">
                DEMAND360<sup>LITE</sup>
              </span>{" "}
              special offer, exclusively available to {partner.name} members for
              a limited time.
            </div>
          </Style>
        )
      } else if (couponValid) {
        return (
          <Style>
            <h2 className="border-b-2">Coupon recognized</h2>
            <div className="copy">
              Please <Link to="/start/">proceed to checkout</Link> to redeem
              your{" "}
              <span className="demand360-brand">
                DEMAND360<sup>LITE</sup>
              </span>{" "}
              special offer, available for a limited time.
            </div>
          </Style>
        )
      } else {
        return null
      }
    }
  } else {
    return null
  }
}

export default CouponBanner
