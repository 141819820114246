import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import { useCookies } from "react-cookie"
import tw, { theme, styled } from "twin.macro"

import CouponContext from "context/coupon-context"
import isBrowser from "utils/is-browser"
import { brandBlock } from "utils/brand"
import Layout from "components/layout"
import SEO from "components/seo"
import CouponBanner from "components/sections/coupon-banner"
import LightSlantedSection from "components/sections/light-slant"
import ArticleShowcase from "components/sections/article-showcase"
import MonitorSubscribe from "components/sections/monitor-subscribe"
import MonitorVideo from "components/sections/monitor-video"
import MonitorCTA from "components/monitor-cta2"
import DemandSample from "components/demand_sample"
import Demand360AvatarCards from "components/sections/demand360-avatar-cards"
import Button from "components/button"
import Demand360LiteHighlights from "components/sections/demand360-lite-highlights"
import Demand360LiteProductProfiles from "components/sections/demand360-lite-product-profiles"
import Demand360LiteAdditionalBenefits from "components/sections/demand360-lite-additional-benefits"
import SectionBackground from "components/section-background"
import FeaturedVideo from "components/sections/featured-video"
import BgWaves from "images/bg/waves.jpg"
import FeaturedPressCarousel from "components/featured-press-carousel"

import { getSharePreviewUrls } from "utils/share-preview"

const ContentBlock = styled.div`
  ${tw`flex flex-wrap items-center overflow-visible -mx-2 text-white font-body`}

  .uvp-header {
    ${tw`w-full text-center flex flex-col items-center px-2 m:pt-32 pb-12`}

    h2 {
      ${tw`font-display font-bold`}
      text-align: center;
      font-size: 30px;
      line-height: 39px;
      @media (min-width: 640px) {
        font-size: 60px;
        line-height: 79px;
      }
    }

    h3 {
      ${tw`font-body`}
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      @media (min-width: 640px) {
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
      }
    }

    > div {
      ${tw`l:w-8/12`}
    }

    .info a {
      width: 15px;
      height: 15px;
      display: inline-block;
      overflow: hidden;
      font-size: 0;
      background-image: url(${`${process.env.GATSBY_ASSETS_BASE_URL}SRA59X5sRGqRHj9qAFIj`});
      background-repeat: no-repeat;
      background-size: contain;
    }

    p {
      color: #bfbfbf;
      font-size: 17px;
      line-height: 27px;
      text-align: center;
    }

    img {
      ${tw`py-8`}
    }
  }

  .bullets {
    ${tw`font-body text-white`}
    font-size: 14px;
    line-height: 1.36;
    @media (min-width: 640px) {
      font-size: 22px;
    }

    ul {
      ${tw`list-disc list-outside`}
      padding-left: 1.5rem;
    }

    li {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
  }

  .demand-report {
    h3 {
      padding-top: 10px;
    }
  }
`

const GreenSlant = styled.div`
  ${tw`font-body text-white overflow-hidden`}
  filter: drop-shadow(0px 2px 0px ${theme`colors.springgreen.light`});
  margin-bottom: -45px;
  position: relative;
  z-index: 1;

  & > .main {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.51) 0%,
        rgba(0, 0, 0, 0) 75.56%
      )
      ${({ background }) => (background ? `, url(${background})` : "")};
    background-color: #110120;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 45px), 0% 100%);
    position: relative;
  }
`

const Demand360Full = styled.div`
  ${tw`mx-auto max-w-s m:max-w-m l:max-w-l mt-8 l:mt-24`}

  .header {
    ${tw`text-white flex flex-col items-center text-center mb-8 l:mb-20`}

    h1 {
      ${tw`font-extrabold text-2xl leading-relaxed l:text-5xl l:leading-relaxed`}
    }

    h5 {
      ${tw`text-lg leading-relaxed l:text-xl l:leading-relaxed`}
    }

    button {
      ${tw`w-full`}

      @media (min-width: 640px) {
        width: 270px;
      }
    }
  }
`

const Demand360Lite = ({ data }) => {
  const queryString = isBrowser ? window.location.search : null
  const [playVideo, setPlayVideo] = useState(false)
  const [ready, setReady] = useState(false)
  const [reportMarket, setReportMarket] = useState(null)
  const [showDemandReport, setShowDemandReport] = useState(false)
  const context = useContext(CouponContext)
  const [_, setCookie] = useCookies(["article"])

  const { gcms } = data
  const { page, markets, gtvdr, headerLogos, demand360 } = gcms

  const headerCta = page.contentBlocks.find(
    (b) => b.identifier === "demand360-lite-header-cta"
  )
  const demandReport = brandBlock(
    page.contentBlocks.find((b) => b.identifier === "global-tv-demand-report")
  )
  const fullProductsAd = brandBlock(
    page.contentBlocks.find(
      (b) => b.identifier === "demand360-lite-full-products-ad"
    )
  )

  const { featuredVideo } = demand360

  const [reportImage, setReportImage] = useState(
    demandReport?.featuredImage?.url
  )

  useEffect(() => {
    if (
      showDemandReport &&
      gtvdr?.insightsArticles &&
      gtvdr?.insightsArticles[0] &&
      gtvdr?.insightsArticles[0].id
    ) {
      setCookie("article", gtvdr?.insightsArticles[0].id, { path: "/" })
    }
  }, [showDemandReport])

  useEffect(() => {
    async function loadCoupon() {
      await context.getCoupon()
      setReady(true)
    }

    if (queryString === null) {
      setReady(true)
    } else {
      const urlParams = new URLSearchParams(queryString)
      const coupon = urlParams.get("coupon")
      if (coupon?.length > 0) {
        loadCoupon(coupon)
      } else {
        setReady(true)
      }
    }
  }, [context, queryString, setReady])

  useEffect(() => {
    const urlParams = new URLSearchParams(queryString)
    const buy = urlParams.get("buy")
    if (buy) {
      const isoCode = buy.substring(6).toUpperCase()
      const market = markets.find((m) => m.isoCode === isoCode)
      if (market) {
        if (["GB", "US"].includes(market.isoCode)) {
          market.name = `the ${market.name}`
        }
        setReportMarket(market)
        if (
          demandReport?.extraData?.reportImages &&
          demandReport.extraData.reportImages[market.isoCode]
        )
          setReportImage(demandReport.extraData.reportImages[market.isoCode])
      } else if (demandReport?.extraData?.reportImages?.ROW) {
        demandReport.countryImage = demandReport.extraData.reportImages.ROW
      }
      setShowDemandReport(true)
    }
  }, [])

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}

      <GreenSlant background={headerCta?.icon?.url}>
        <div className="main">
          <CouponBanner />
          {demandReport && showDemandReport && (
            <div className="mx-auto max-w-s m:max-w-m l:max-w-l px-2">
              <ContentBlock className="pt-32 demand-report">
                <div
                  className="uvp-header"
                  dangerouslySetInnerHTML={{
                    __html: demandReport?.headerContent?.html,
                  }}
                />
                <div className="flex flex-wrap -mx-2">
                  {reportImage && (
                    <div className="w-full l:w-1/2 px-2 flex flex-col justify-start pb-4 m:pb-8 l:py-0">
                      <img
                        src={reportImage}
                        alt={demandReport?.featuredImage?.altText}
                      />
                      {!!reportMarket && (
                        <div className="bg-black flex flex-row">
                          <div className="relative w-1/4 m:w-1/6 object-cover">
                            <img
                              src={`https://parrot-portalassets.s3.amazonaws.com/flags/${reportMarket.isoCode.toLowerCase()}.png`}
                              alt=""
                              className="absolute w-full h-full object-cover"
                            />
                          </div>
                          {demandReport?.extraData?.featuredImageCaption && (
                            <div className="p-2 m:px-4 text-xs m:text-xl font-italic italic flex flex-col justify-center w-3/4 m:w-5/6">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    demandReport.extraData.featuredImageCaption.replace(
                                      "{{market}}",
                                      reportMarket.name
                                    ),
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {demandReport?.mainContent?.html && (
                    <div className="px-2 w-full l:w-1/2 l:pl-16">
                      <div
                        className="bullets"
                        dangerouslySetInnerHTML={{
                          __html: demandReport.mainContent.html,
                        }}
                      />
                    </div>
                  )}
                </div>
              </ContentBlock>
            </div>
          )}
          {headerCta && (
            <MonitorCTA
              content={headerCta}
              logos={headerLogos}
              playVideo={playVideo}
              onPlayVideo={(playing) => setPlayVideo(playing)}
            />
          )}
        </div>
      </GreenSlant>

      <SectionBackground bg={BgWaves} color="#0c0c0c" opacity="0.25">
        <div className="py-32 -mb-32 overflow-hidden">
          <DemandSample onPlayVideo={() => setPlayVideo(true)} />

          <div className="pt-32 pb-8 mx-auto max-w-s m:max-w-m l:max-w-l">
            <Demand360LiteHighlights />
          </div>

          <div className="mt-16 l:mt-24 pb-8 mx-auto max-w-s m:max-w-m l:max-w-l">
            <Demand360LiteAdditionalBenefits />
          </div>

          <div className="pt-16 l:pt-32 pb-8 mx-auto max-w-s m:max-w-m l:max-w-l">
            <MonitorSubscribe video={true} />
          </div>

          <MonitorVideo />

          <div className="mt-8 l:mt-16 pb-8 mx-auto max-w-s m:max-w-m l:max-w-l">
            <Demand360LiteProductProfiles />
          </div>

          <div className="pb-8 mt-24 mx-auto max-w-s m:max-w-m l:max-w-l">
            <MonitorSubscribe header={true} faq={true} />
          </div>

          {fullProductsAd && (
            <Demand360Full>
              <div className="header">
                <h1>{fullProductsAd.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: fullProductsAd.headerContent.html,
                  }}
                />
                <div className="flex flex-col l:flex-row mt-8 l:mt-16 space-y-6 l:space-y-0 space-x-0 l:space-x-4">
                  <Button
                    to={fullProductsAd.linkUrl}
                    className="secondary red-text"
                  >
                    {fullProductsAd.callToAction}
                  </Button>
                  <Button to="/contact" className="red">
                    Contact Us
                  </Button>
                </div>
              </div>
              <Demand360AvatarCards />
            </Demand360Full>
          )}

          {featuredVideo && (
            <div className="mt-24">
              <FeaturedVideo video={featuredVideo} />
            </div>
          )}

          {page?.featuredPressArticles?.length > 0 && (
            <div className="mt-12 mb-44">
              <FeaturedPressCarousel
                className="dark"
                articles={page.featuredPressArticles}
              />
            </div>
          )}
        </div>
      </SectionBackground>

      {page?.articleShowcase?.length > 0 && (
        <LightSlantedSection>
          <ArticleShowcase
            articles={page.articleShowcase}
            title="Driving industry thought leadership"
          />
        </LightSlantedSection>
      )}
    </Layout>
  )
}

export default Demand360Lite

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/products/demand360/lite" }) {
        ...PageData
      }
      demand360: page(where: { path: "/products/demand360" }) {
        ...PageData
      }
      headerLogos: featuredLogosClients(
        where: { id: "ckh9bpgiifjk00957jd3z2xrd" }
      ) {
        heading
        headingColor {
          hex
        }
        logos {
          url
          altText
        }
      }
      markets {
        isoCode
        name
      }
      gtvdr: insightsArticleSeries(where: { id: "ck7q3ytepse7r09980nxcjes3" }) {
        insightsArticles(orderBy: publishingDate_DESC, first: 1) {
          id
        }
      }
    }
  }
`
