import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import { brandBlock } from "utils/brand"

const Styles = styled.div`
  ${tw`flex flex-col items-center overflow-visible -mx-2 text-white`}

  font-size: 18px;
  line-height: 30px;
  @media (min-width: 640px) {
    font-size: 22px;
    line-height: 33px;
  }

  h2 {
    ${tw`font-display font-bold`}
    text-align: center;
    font-size: 30px;
    line-height: 39px;
    @media (min-width: 640px) {
      font-size: 60px;
      line-height: 79px;
    }
  }

  .description {
    ${tw`w-full l:w-3/4 text-center text-grey-65`}
  }

  .items {
    ${tw`flex flex-col justify-center l:grid l:grid-cols-3
       gap-y-10 gap-x-5 mt-16 text-center`}

    .item {
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
      border-radius: 10px;

      .image {
        height: 229px;

        img {
          ${tw`w-full object-cover object-top`}

          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          height: 229px;
        }
      }

      .content {
        ${tw`pt-4 pb-8 px-4`}

        font-size: 15px;
        line-height: 24px;
        @media (min-width: 640px) {
          font-size: 16px;
          line-height: 28px;
        }

        h5 {
          ${tw`text-springgreen-light font-display font-bold mb-2 px-12`}

          @media (min-width: 640px) {
            font-size: 27px;
            line-height: 40px;
          }
        }
      }
    }
  }
`

export default () => {
  const query = graphql`
    query AdditionalBenefits {
      gcms {
        contentBlocks(
          where: { identifier: "demand360-lite-additional-benefits" }
        ) {
          title
          headerContent {
            html
          }
          extraData
        }
      }
    }
  `

  const data = useStaticQuery(query)
  const benefits = brandBlock(data.gcms.contentBlocks[0])

  if (!benefits) {
    return null
  }

  return (
    <Styles>
      <h2>{benefits.title}</h2>
      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: benefits.headerContent.html,
        }}
      />
      <div className="items">
        {benefits.extraData?.items.map((item, i) => (
          <div className="item" key={i}>
            <div className="image">
              <img src={item.image} />
            </div>
            <div className="content">
              <h5>{item.title}</h5>
              <p>{item.content}</p>
            </div>
          </div>
        ))}
      </div>
    </Styles>
  )
}
