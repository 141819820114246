import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import { brandBlock, brandRules } from "utils/brand"
import Button from "components/button"
import PlanPrice from "components/plan-price"
import PlayIcon from "images/icons/play.svg"
import Modal from "components/modal"
import YouTube from "components/youtube.js"

const MonitorSubscribeStyle = styled.div`
  ${tw`flex flex-col items-center overflow-visible -mx-2 text-white`}

  .header {
    ${tw`w-full l:w-3/4 text-center flex flex-col items-center px-2 pb-10 text-grey-65`}

    font-size: 15px;
    line-height: 24px;
    @media (min-width: 640px) {
      font-size: 22px;
      line-height: 33px;
    }
  }

  h2 {
    ${tw`font-display font-bold`}
    text-align: center;
    font-size: 30px;
    line-height: 39px;
    @media (min-width: 640px) {
      font-size: 60px;
      line-height: 79px;
    }
  }

  .main {
    ${tw`overflow-hidden text-white flex flex-wrap justify-between p-8 w-full`}
    background: linear-gradient(180deg, #2c2c2c 0%, #191919 100%);
    border-radius: 5px;

    .copy {
      ${tw`text-white font-body`}
      font-size: 15px;
      letter-spacing: 0;
      line-height: 1.8;
      max-width: 720px;
      padding-bottom: 16px;

      h3 {
        ${tw`font-display font-bold`}
        font-size: 21px;
        line-height: 1.43;
      }

      .footer {
        margin-top: 30px;
        p {
          ${tw`text-grey-45`}
        }
        a {
          ${tw`font-display`}
          text-decoration: underline;
          text-underline-offset: 6px;
        }
      }
    }

    .price {
      max-width: auto;
      @media (min-width: 1200px) {
        max-width: 120px;
      }
    }

    .cta {
      max-width: 240px;
    }
  }
`

const MonitorSubscribe = ({ header = false, faq = false, video = false }) => {
  const [modal, setModal] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      gcms {
        content: contentBlocks(
          where: { identifier: "demand360-lite-subscription-cta" }
          first: 1
          orderBy: order_ASC
        ) {
          title
          headerContent {
            html
          }
          mainContent {
            html
          }
          callToAction
          linkUrl
          extraData
        }
      }
    }
  `)

  if (data.gcms.content?.length < 1) {
    return <></>
  }

  const content = brandBlock(data.gcms.content[0])

  content.mainContent.html = data.gcms.content[0].mainContent.html

  const videoAvailable =
    content.extraData?.videoCTA && content.extraData?.youTubeID

  return (
    <MonitorSubscribeStyle>
      {header && (
        <>
          <h2 dangerouslySetInnerHTML={{ __html: brandRules(content.title) }} />
          <div
            className="header"
            dangerouslySetInnerHTML={{
              __html: content?.headerContent?.html,
            }}
          />
        </>
      )}
      <div className="main">
        <div
          className="copy"
          dangerouslySetInnerHTML={{
            __html: content?.mainContent?.html,
          }}
        />
        <div className="price flex flex-grow">
          <PlanPrice />
        </div>
        <div className="cta pt-4 m:pt-0 flex flex-col justify-center items-center w-full">
          <Button to={content.linkUrl} className="my-3 w-full">
            {content.callToAction}
          </Button>
          {(faq || !videoAvailable) && (
            <Button
              to={content.extraData.faqLink}
              className="my-3 w-full secondary white-text"
            >
              {content.extraData.faqCTA}
            </Button>
          )}
          {videoAvailable && video && (
            <Button
              onClick={() => setModal(true)}
              className="my-3 w-full secondary white-text monitor"
            >
              <div className="flex flex-row items-center justify-center">
                <img src={PlayIcon} className="mr-3" height="20" width="20" />
                {content.extraData.videoCTA}
              </div>
            </Button>
          )}
        </div>
      </div>
      {videoAvailable && modal && (
        <Modal isVisible={modal} onClose={() => setModal(false)}>
          <div className="video-player h-full w-full m:px-16">
            <YouTube
              videoId={content.extraData.youTubeID}
              active={true}
              autoplay={true}
              fixAspectRatio={false}
            />
          </div>
        </Modal>
      )}
    </MonitorSubscribeStyle>
  )
}

export default MonitorSubscribe
