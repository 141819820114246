import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from 'twin.macro'

import ProductProfile from "components/product-profile";
import VerticalLine from "components/vertical-line";
import { brandBlock } from "utils/brand";
import { getProductId, getLiteProducts } from "utils/products"
import Logo from "images/logos/demand360-lite-product.png";

const LiteProduct = styled.div`
  ${tw`flex flex-col`}

  .header {
    ${tw`text-center l:w-11/12`}

    img {
      ${tw`mx-auto`}

      height: 16px;

      @media (min-width: 640px) {
        height: 30px;
      }
    }

    h1 {
      ${tw`py-2 w-full font-display font-bold text-white leading-loose`}

      font-size: 35px;

      @media (min-width: 640px) {
        font-size: 45px;
      }

      @media (min-width: 1024px) {
        font-size: 60px;
      }
    }

    h2 {
      ${tw`font-display leading-loose text-yellow`}
      font-size: 22px;
    }

    p {
      ${tw`text-grey-65 leading-loose l:w-11/12 mx-auto`}
      font-size: 18px;
    }
  }
`

function mapContentBlock(content, page) {
  content = brandBlock(content);

  return {
    id: content.id,
    heroImage: content.featuredImage,
    extraData: page.extraData,
    title: content.title,
    body: content.mainContent,
  };
}

export default () => {
  const query = graphql`
    query LiteProductProfiles {
      gcms {
        pages(where: { path_contains: "/lite/" }, orderBy: order_ASC) {
          ...PageData
        }
      }
    }
  `;

  const data = useStaticQuery(query);

  const pages = getLiteProducts(data.gcms.pages);

  return (
    <>
      {pages.map((page) => {
        page = brandBlock(page);
        return (
          <LiteProduct key={page.id} id={getProductId(page)}>
            <div className="header">
              <img src={Logo} alt="" />
              <div
                dangerouslySetInnerHTML={{
                  __html: page.headerContent.html,
                }}
              />
            </div>

            <div className="relative pt-16 l:pt-32 w-full">
              <VerticalLine className="products" />

              {page.contentBlocks.map(feature => (
                <ProductProfile
                  page={mapContentBlock(feature, page)}
                  key={feature.id}
                />
              ))}
            </div>

          </LiteProduct>
        )
      })}
    </>
  );
}
