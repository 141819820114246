import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import ButtonLE from "components/button-le"
import Logo from "images/logos/demand360-lite-product.png"
import { getProductId, getLiteProducts } from "utils/products"
import Check from "images/icons/check-grey.svg"
import Earth from "images/icons/earth.png"

const Styles = styled.div`
  ${tw`flex flex-col mx-auto max-w-s m:max-w-m l:max-w-l`}

  .header {
    ${tw`text-center flex flex-col items-center`}

    h1 {
      ${tw`text-white font-extrabold text-2xl l:text-5xl leading-relaxed l:leading-relaxed`}
    }

    div {
      ${tw`text-grey-65 l:w-3/5 text-lg l:text-xl leading-relaxed l:leading-relaxed`}
    }

    img {
      ${tw`mt-12`}

      width: 511px;
    }
  }

  .pages {
    ${tw`mt-0 l:mt-16 w-full flex flex-col l:grid l:grid-cols-3 l:auto-rows-auto gap-x-5`}

    & > div {
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
    }

    .top {
      ${tw`mt-16 l:mt-0`}

      & > div {
        background: rgba(0, 0, 0, 0.15);
      }

      border-radius: 5px 5px 0 0;

      .image {
        ${tw`relative`}

        .title {
          ${tw`absolute text-white font-extrabold capitalize leading-none`}

          font-size: 23px;
          text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.18);
          left: 17px;
          top: 10px;

          @media (min-width: 640px) {
            font-size: 26px;
            left: 20px;
            top: 17px;
          }
        }

        img {
          border-radius: 5px;
        }
      }

      .access {
        ${tw`flex flex-row items-baseline justify-center text-grey-65 uppercase py-3 space-x-2`}

        img {
          height: 16px;
          width: 16px;
        }
      }
    }

    .highlights {
      ${tw`flex flex-col text-base leading-normal text-white space-y-16 py-10`}

      .highlight {
        ${tw`flex flex-row items-center px-5 space-x-5`}
      }
    }

    .actions {
      ${tw`px-5 py-4 l:pt-0 l:pb-8 flex items-center l:items-start justify-center l:justify-start`}

      border-radius: 0 0 5px 5px;
    }
  }
`

const Icon = styled.img`
  ${tw`inline-block flex-shrink-0`}

  width: 36px;
`

export default () => {
  const query = graphql`
    query LitePages {
      gcms {
        pages(where: { path_contains: "/lite/" }, orderBy: order_ASC) {
          ...PageData
        }
        contentBlocks(where: { identifier: "demand360-lite-highlights" }) {
          title
          headerContent {
            html
          }
        }
      }
    }
  `

  const data = useStaticQuery(query)
  const { contentBlocks } = data.gcms
  const pages = getLiteProducts(data.gcms.pages)
  const { title, headerContent } = contentBlocks[0] || {}

  const withHighlights = pages.filter(
    (page) => page.extraData?.highlights?.length > 0
  )

  return (
    <Styles>
      <div className="header">
        <h1>{title}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: headerContent.html,
          }}
        />
        <img src={Logo} />
      </div>

      <div className="pages">
        {withHighlights.map((page) => (
          <Fragment key={page.id}>
            <div className="top row-start-1 row-end-1">
              <div className="image">
                {page.description && (
                  <div className="title">
                    {page.description}
                    {page.extraData?.titleSuffix && (
                      <span className="uppercase text-grey-65">
                        &nbsp;{page.extraData?.titleSuffix}
                      </span>
                    )}
                  </div>
                )}
                <img src={page.featuredImage.url} />
              </div>
              <div className="access">
                <img src={Check} />
                <span>{page.extraData.access}</span>
              </div>
            </div>
            <div className="highlights row-start-2 row-end-2">
              {page.extraData.highlights.map(({ icon, text }, i) => (
                <div key={i} className="highlight">
                  <Icon src={icon || Earth}></Icon>
                  <div>{text}</div>
                </div>
              ))}
            </div>
            <div className="actions row-start-3 row-end-3">
              <ButtonLE to={`#${getProductId(page)}`} color="green">
                Find out more
              </ButtonLE>
            </div>
          </Fragment>
        ))}
      </div>
    </Styles>
  )
}
