import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import { getProducts } from "utils/products"
import ProductGroupCard from "components/product-group-card"

const Styles = styled.div`
  ${tw`w-full flex flex-col items-center justify-center l:grid l:grid-cols-3
       gap-y-8 gap-x-5 mx-auto max-w-s m:max-w-m l:max-w-l`}
`

export default () => {
  const query = graphql`
    query ProductAvatars {
      gcms {
        pages(
          where: {
            path_in: [
              "/products/tv-demand"
              "/products/talent-demand"
              "/products/movie-demand"
            ]
          }
          orderBy: order_ASC
        ) {
          ...PageData
        }
      }
    }
  `

  const data = useStaticQuery(query)

  const pages = getProducts(data.gcms.pages)

  return (
    <Styles>
      {pages.map((page) => (
        <ProductGroupCard key={page.id} cardInfo={page} />
      ))}
    </Styles>
  )
}
