import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import { brandBlock, brandRules } from "utils/brand"
import VideoModal from "./video-modal"

const StyleContainer = styled.div`
  ${tw`flex flex-col items-center overflow-visible -mx-2 text-white`}

  .header {
    ${tw`w-full text-center flex flex-col items-center px-2 pb-10`}

    p {
      color: #bfbfbf;
    }

    .copy {
      ${tw`l:w-3/4`}
    }

    & > img {
      ${tw`py-8`}
    }
  }

  p {
    ${tw`text-white font-body`}
    font-size: 15px;
    line-height: 24px;
    @media (min-width: 640px) {
      font-size: 17px;
      line-height: 27px;
    }
  }

  h2 {
    ${tw`font-display font-bold`}
    text-align: center;
    font-size: 30px;
    line-height: 39px;
    @media (min-width: 640px) {
      font-size: 60px;
      line-height: 79px;
    }
  }
`

const MonitorVideo = () => {
  const data = useStaticQuery(graphql`
    query {
      gcms {
        content: contentBlocks(
          where: { identifier: "demand360-lite-video-section" }
          first: 1
          orderBy: order_ASC
        ) {
          icon {
            url
            altText
          }
          title
          mainContent {
            html
          }
          headerContent {
            html
          }
          callToAction
          linkUrl
          extraData
        }
      }
    }
  `)

  const content = brandBlock(data.gcms.content?.[0])

  return (
    <div className="mt-16 l:mt-32 mb-40 mx-auto m:max-w-m l:max-w-l">
      <StyleContainer>
        <div className="header">
          {content.icon && (
            <img src={content?.icon.url} alt={content?.icon?.altText} />
          )}
          <h2 dangerouslySetInnerHTML={{ __html: brandRules(content.title) }} />
          <div
            className="copy"
            dangerouslySetInnerHTML={{
              __html: content?.headerContent?.html,
            }}
          />
          <VideoModal
            id="demo"
            className="relative w-full h-full mt-16"
            data={content?.extraData || {}}
            autoplayHash="play"
          />
        </div>
      </StyleContainer>
    </div>
  )
}

export default MonitorVideo
