import React, { useContext, useEffect, useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"
import { motion, AnimatePresence } from "framer-motion"

import GeoContext from "context/geo-context"
import Button from "components/button"
import IconGenre from "images/icons/demand_sample/genre.svg"
import IconRank from "images/icons/demand_sample/rank.svg"
import IconShow from "images/icons/demand_sample/show.svg"
import { posterUrl } from "utils/images"

const Styles = styled.div`
  ${tw`font-body text-white`}

  h2 {
    ${tw`py-8 m:pb-16`}
    font-size: 24px;
    line-height: 1.3;
    text-align: center;
    @media (min-width: 640px) {
      font-size: 35px;
    }
  }

  .sample {
    overflow: hidden;
    position: relative;
    background-image: linear-gradient(
      0deg,
      rgba(144, 19, 254, 0.5) 0%,
      rgba(0, 0, 0, 0) 66%
    );
    @media (min-width: 640px) {
      background-image: linear-gradient(
        90deg,
        rgba(144, 19, 254, 1) 0%,
        rgba(0, 0, 0, 0) 66%
      );
      height: 300px;
    }

    .cta {
      ${tw`flex flex-row items-center justify-center p-2 m:p-8`}
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      font-size: 24px;
      line-height: 2;
      text-align: center;
      backdrop-filter: blur(1px);
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 2;
      @media (min-width: 640px) {
        font-size: 35px;
      }

      a {
        ${tw`text-springgreen`}
      }
    }

    .show {
      ${tw`flex flex-col m:flex-row m:flex-wrap l:flex-nowrap items-center justify-center m:justify-between`}
    }

    .preloader {
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      opacity: 0;
    }

    .cover {
      ${tw`flex-grow-0 flex-shrink-0 relative`}
      width: 200px;
      height: 300px;

      img {
        box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.5);
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }

    .caption {
      ${tw`flex flex-col items-start justify-center py-6 l:py-0`}
      font-size: 22px;
      line-height: 1.57;
      letter-spacing: normal;
      padding-left: 20px;
      padding-right: 20px;
      max-width: 500px;
      @media (min-width: 640px) {
        font-size: 27px;
      }
      @media (min-width: 1200px) {
        font-size: 30px;
        max-width: 100%;
        padding-left: 28px;
        padding-right: 28px;
      }

      .data {
        ${tw`text-yellow font-display`}
      }
    }

    .details {
      ${tw`flex flex-col justify-between flex-shrink-0 m:hidden flex-wrap l:flex px-2 l:px-0`}
      font-size: 15px;
      height: 100%;
      padding-bottom: 0.5rem;
      position: relative;
      @media (min-width: 1200px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 330px;
        font-size: 18px;
      }

      .line {
        border-left: 1px solid white;
        top: 0;
        left: 22px;
        height: 100%;
        position: absolute;
        width: 1px;
        @media (min-width: 640px) {
          display: none;
        }
        @media (min-width: 1200px) {
          display: block;
          left: 22px;
        }
      }

      .detail {
        ${tw`relative py-2 flex flex-row items-center`}
        z-index: 1;

        .icon {
          ${tw`flex-shrink-0 flex-grow-0 flex flex-col items-center`}
          margin-right: 5px;
          height: 30px;
          width: 30px;
          @media (min-width: 1200px) {
            margin-right: 10px;
            height: 45px;
            width: 45px;
          }

          img {
            height: 100%;
            width: 100%;
          }
        }

        .flag {
          ${tw`flex-shrink-0 flex-grow-0 flex flex-col items-center justify-center`}
          background-color: #6303b7;
          border-radius: 9999px;
          margin-right: 5px;
          height: 30px;
          width: 30px;
          @media (min-width: 1200px) {
            margin-right: 10px;
            height: 45px;
            width: 45px;
          }

          div {
            ${tw`flex-shrink-0 flex-grow-0 flex flex-col items-center justify-center`}
            border-radius: 9999px;
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
            height: 20px;
            width: 20px;
            @media (min-width: 1200px) {
              height: 30px;
              width: 30px;
            }
            overflow: hidden;
          }

          img {
            height: 20px;
            min-width: 28px;
            @media (min-width: 1200px) {
              height: 30px;
              width: 42px;
            }
          }
        }

        .label {
          ${tw`text-yellow inline-block flex-shrink-0 flex-grow-0`}
          width: 85px;
        }

        .data {
          max-height: 54px;
          overflow: hidden;
        }
      }
    }
  }
`

const DemandSample = ({ onPlayVideo, secondaryCtaClick }) => {
  const data = useStaticQuery(graphql`
    {
      demandSampleJson {
        markets {
          country_iso
          country_name
          market_name
          slug
          titles {
            genre
            slug
            title
            genre_rank
            parrot_id
          }
        }
      }
      gcms {
        websiteSetting(where: { name: "Measurement page localization" }) {
          jsonValue
        }
      }
    }
  `)
  const { markets } = data.demandSampleJson
  const { jsonValue: settings } = data.gcms.websiteSetting
  const [market, setMarket] = useState(null)
  const [show, setShow] = useState(null)
  const [showIdx, setShowIdx] = useState(0)
  const [showCTA, setShowCTA] = useState(false)
  const geoContext = useContext(GeoContext)
  const animVariants = {
    shown: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  }

  function switchMarket(market) {
    setMarket(market)
    setShow(market.titles[0])
  }

  function refreshShow(e) {
    e && e.preventDefault()
    if (market) {
      if (showIdx < market.titles.length) {
        setShow(market.titles[showIdx])
        setShowIdx(showIdx + 1)
        setShowCTA(false)
      } else {
        setShowCTA(true)
      }
    }
  }

  function callVideo(e) {
    e && e.preventDefault()
    onPlayVideo && onPlayVideo()
  }

  useEffect(() => refreshShow(), [market])

  useEffect(() => {
    async function loadMarket() {
      const defaultMarket =
        markets.find((m) => m.country_iso === settings.default) || markets[0]
      const geo = await geoContext.get()
      if (geo && geo.code) {
        const selectedMarket = markets.find((m) => m.country_iso === geo.code)
        if (selectedMarket) {
          switchMarket(selectedMarket)
        } else {
          switchMarket(defaultMarket)
        }
      } else {
        switchMarket(defaultMarket)
      }
    }
    if (markets) {
      loadMarket()
    }
  }, [markets])

  const titleLen = 50
  let shortTitle = show?.title || ""
  if (shortTitle.length > titleLen) {
    //trim the string to the maximum length
    shortTitle = shortTitle.substr(0, titleLen)

    //re-trim if we are in the middle of a word and
    shortTitle = shortTitle.substr(
      0,
      Math.min(shortTitle.length, shortTitle.lastIndexOf(" "))
    )
    shortTitle = shortTitle + "..."
  }

  if (!markets || markets[0].country_iso === "") {
    return null
  } else {
    return (
      <Styles>
        <div className="mx-auto max-w-s m:max-w-m l:max-w-l">
          <h2>
            <span className="demand360-brand">
              DEMAND360<sup>LITE</sup>
            </span>{" "}
            in action: Real-time insights for{" "}
            <span className="text-yellow whitespace-nowrap">
              {market && market.market_name}
            </span>
          </h2>
          <div className="sample">
            <AnimatePresence initial={false} exitBeforeEnter>
              {!!show && (
                <div className="show">
                  <div
                    className="preloader"
                    style={{
                      backgroundImage: market.titles
                        .map((t) => posterUrl(t.parrot_id, "EXTRA_LARGE_DPR_2"))
                        .join(","),
                    }}
                  />
                  <div className="cover">
                    <motion.img
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      src={posterUrl(show.parrot_id, "EXTRA_LARGE_DPR_2")}
                      alt={show.title}
                      key={show.parrot_id}
                    />
                  </div>
                  <div className="caption">
                    <motion.p
                      key={`caption-${show.parrot_id}`}
                      initial="hidden"
                      animate="shown"
                      exit="hidden"
                      transition={{ staggerChildren: 0.1 }}
                      variants={animVariants}
                    >
                      <motion.span variants={animVariants}>
                        Over the last 30 days,{" "}
                      </motion.span>
                      <motion.span variants={animVariants} className="data">
                        {shortTitle}{" "}
                      </motion.span>
                      <motion.span variants={animVariants}>
                        {" "}
                        was the{" "}
                      </motion.span>
                      <motion.span variants={animVariants} className="data">
                        #{show.genre_rank}{" "}
                      </motion.span>
                      <motion.span variants={animVariants}>
                        most in-demand{" "}
                      </motion.span>
                      <motion.span variants={animVariants} className="data">
                        {show.genre}{" "}
                      </motion.span>
                      <motion.span variants={animVariants}>
                        TV show in{" "}
                      </motion.span>
                      <motion.span variants={animVariants} className="data">
                        {market.market_name}.
                      </motion.span>
                    </motion.p>
                    <div className="w-full text-center m:text-left">
                      <Button className="red pt-2" onClick={refreshShow}>
                        Refresh
                      </Button>
                    </div>
                  </div>

                  <div className="details">
                    <motion.div
                      className="line"
                      key={`line-${show.parrot_id}`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    />
                    <motion.ul
                      key={`details-${show.parrot_id}`}
                      initial="hidden"
                      animate="shown"
                      exit="hidden"
                      transition={{ staggerChildren: 0.2 }}
                      variants={animVariants}
                    >
                      <motion.li
                        variants={animVariants}
                        key={`show-${show.parrot_id}`}
                        className="detail"
                      >
                        <div className="icon">
                          <img src={IconShow} alt="" />
                        </div>
                        <div className="label">TV Show:</div>
                        <div className="data">{shortTitle}</div>
                      </motion.li>
                      <motion.li
                        variants={animVariants}
                        key={`genre-${show.parrot_id}`}
                        className="detail"
                      >
                        <div className="icon">
                          <img src={IconGenre} alt="" />
                        </div>
                        <div className="label">Genre:</div>
                        <div className="data">{show.genre}</div>
                      </motion.li>
                      <motion.li
                        variants={animVariants}
                        key={`rank-${show.parrot_id}`}
                        className="detail"
                      >
                        <div className="icon">
                          <img src={IconRank} alt="" />
                        </div>
                        <div className="label">Rank:</div>
                        <div className="data">#{show.genre_rank}</div>
                      </motion.li>
                      <motion.li
                        variants={animVariants}
                        key={`market-${show.parrot_id}`}
                        className="detail"
                      >
                        <div className="flag">
                          <div>
                            <img
                              src={`https://parrot-portalassets.s3.amazonaws.com/flags/${market.country_iso.toLowerCase()}.png`}
                              alt={`Flag of ${market.market_name}`}
                            />
                          </div>
                        </div>
                        <div className="label">Market:</div>
                        <div className="data">{market.country_name}</div>
                      </motion.li>
                    </motion.ul>
                  </div>
                </div>
              )}
              {showCTA && (
                <motion.div
                  initial={{ top: "-100%", opacity: 0 }}
                  animate={{ top: "0%", opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="cta"
                  key="showCTA"
                >
                  <div>
                    Did you enjoy your free insights?{" "}
                    <Link to="/start/">Sign up now</Link> to{" "}
                    <span className="demand360-brand">
                      DEMAND360<sup>LITE</sup>
                    </span>{" "}
                    or watch our product{" "}
                    <a href="#" onClick={callVideo}>
                      demo video
                    </a>
                    .
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </Styles>
    )
  }
}

export default DemandSample
